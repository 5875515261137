import React, { Component } from 'react';
import ContactSection from '../Contact/ContactSection';
import { Footer } from '../Footer/Footer';
import wheel from './Wheel.png'
import CollapsibleList from '../CollapsibleList/CollapsibleList';
import MelbourneWealthAdvisory from '../Team/MelbourneWealthAdvisory';
import './WealthAdvisory.css'
import MelbournePortfolioServices from '../Team/MelbournePortfolioServices';

class WealthAdvisory extends Component {
    render() {
        const listData = [
            {
                title: "We think long term",
                body: '<p>Sanlam\’s mission is to provide prudent, honest, objective and appropriate advice to help our clients achieve long term financial security in a judicious manner.</p> \
                        <p>Our service is built upon personal trust and is based on genuine long-term relationships with our clients.</p><br />'
            },
            {
                title: "What\'s the first step?",
                body: '<p>Sanlam\’s in-depth consultation process enables us to truly understand your personal and/or business circumstances, financial position, and most importantly, the things that are most important to you.</p> \
                        <p>Only after this process can we deliver you meaningful and valuable financial advice that aligns with your very personal objectives.</p><br />'
            },
            {
                title: "Working as a team",
                body: '<p>Often we will work in conjunction with clients\’ existing accountants and legal advisers, ensuring that advice is coordinated and aligned.</p> \
                        <p>Alternatively, access to specialist advice provided through our well-established networks and longstanding relationships can introduce other professionals into the relationship if necessary.</p><br />'
            },
        ]
        return (
            <div className="component-container">
                <div className="wealth-advisory">
                    <section className="header">
                        <div className="content">
                            <img src={wheel} alt="Sanlam wealth advisory" />
                            <div className="text">
                                <h5>WEALTH ADVISORY</h5>
                                <h1>Meaningful advice<br /><span>tailored to you</span></h1>
                                <p>
                                    Sanlam Private Wealth Advisory has expertise spanning a wide range of core and specialist financial planning areas. We advise private clients, business owners, family groups, as well as sophisticated and professional investors. Our overriding aim is to deliver meaningful financial advice, strategic in nature, that is tailored very specifically to your unique set of circumstances. 
                                </p>
                                <h2>Financial Planning, Super,<br />Retirement, Investment advice</h2><a href="https://sanlam.net.au" target="_blank"><h4>Click here to find out more.</h4></a>
                            </div>
                        </div>
                    </section>
                    <section className="list-component services">
                        <div className="content">
                            <div className="divider">
                                <div className="services-summary">
                                    <h5>OUR PROCESS</h5>
                                    <h1>Finance for <span>life objectives</span></h1>
                                    <p>
                                        Importantly, we prefer to employ a comprehensive and holistic approach to clients’ advice requirements. 
                                        <br />
                                        We aim to have a genuine impact on not only the financial affairs of our clients, but their lifestyle goals and objectives also. After all, financial planning is a means by which to achieve lifestyle wellbeing and ensure your life’s objectives are never compromised.
                                    </p>
                                </div>
                                <div className="services-illustration">

                                </div>
                            </div>
                            <div className="divider">
                                <CollapsibleList data={listData} />
                            </div>
                        </div>
                    </section>
                    <MelbourneWealthAdvisory />
                    <ContactSection activeTopic="wealth advisory" />
                    <Footer />
                </div>
            </div>
        )
    }
}

export default WealthAdvisory
